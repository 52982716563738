<h2 mat-dialog-title>2. Online market research</h2>
<mat-dialog-content class="mat-typography">
  <ol>
    <li>Search for a machine using the search bar</li>
    <li>Use filters to narrow down the results</li>
    <li>
      Use the checkboxes to select which ones will be considered in the
      evaluation
    </li>
    <li>
      Include price adjustments with comments when necessary (ie: extra
      attachments)
    </li>
    <li>Manually add extra machines (with auction results) if needed</li>
  </ol>
  <h3>Info</h3>
  <p>
    Only consider machines around the year of construction of the machine to be
    valued (+/- 8 years).
  </p>
  <p>
    Do not include defective machines or machines without hours, a picture and a
    selling price.
  </p>
  <p>
    Do not include machines that only show the starting price of an auction.
  </p>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    Never show again
  </button>
  <button mat-button [mat-dialog-close]="false" class="navigate">
    <mat-icon>navigate_next</mat-icon>
  </button>
</mat-dialog-actions>
