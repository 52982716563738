import { Component } from '@angular/core';
import { EvaService } from '../eva.service';
import { MatDialogRef } from '@angular/material/dialog';
import { EvaluationData } from '../data/evaluation-data';

@Component({
  selector: 'app-custom-machine-dialog',
  templateUrl: './custom-machine-dialog.component.html',
  styleUrls: ['./custom-machine-dialog.component.scss'],
})
export class CustomMachineDialogComponent {
  new_machine: EvaluationData;
  constructor(
    public evaService: EvaService,
    public dialogRef: MatDialogRef<CustomMachineDialogComponent>
  ) {
    const now = new Date();
    this.new_machine = new EvaluationData(
      '',
      0,
      0,
      0,
      '',
      0,
      '',
      '',
      '',
      false,
      '',
      0,
      0,
      0,
      '',
      '',
      ''
    );
  }

  sendMachineData() {
    this.dialogRef.close(this.prepareMachineData(this.new_machine));
  }

  prepareMachineData(data: EvaluationData): EvaluationData {
    if(data.dateInfo) {
      const dateInfo = new Date(data.dateInfo);
      const year = dateInfo.getFullYear();
      data.date = year;
    }

    return data;
  }

  dismissData() {
    this.dialogRef.close(false);
  }
}
