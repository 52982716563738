import { Component, Inject } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Filter } from '../data/filter';
import { EvaService } from '../eva.service';
import { exclude } from '../utils/arrays';

@Component({
  selector: 'app-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
})
export class FilterDialogComponent {
  filter: Filter;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public evaService: EvaService
  ) {
    this.filter = data.filter;
  }

  toggleCountry(country: string) {
    const countries = this.filter.location.split(',');
    if (countries.includes(country)) exclude(countries, country);
    else countries.push(country);
    this.filter.location = countries.join(',');
  }

  toggleSource(source: string) {
    const sources = this.filter.source.split(',');
    if (sources.includes(source)) exclude(sources, source);
    else sources.push(source);
    this.filter.source = sources.join(',');
  }

  isAllCountriesChecked(): boolean {
    if (!this.evaService.eva) return false;
    const countries = this.filter.location.split(',');
    return !this.evaService.eva.countries.find((c) => !countries.includes(c));
  }

  isAllSourcesChecked(): boolean {
    if (!this.evaService.eva) return false;
    const sources = this.filter.source.split(',');
    return !this.evaService.eva.sources.find((s) => !sources.includes(s));
  }

  isSomeSourcesChecked(): boolean {
    if (!this.evaService.eva) return false;
    const sources = this.filter.source.split(',');
    return (
      !!this.evaService.eva.sources.find((s) => !sources.includes(s)) &&
      !!this.evaService.eva.sources.find((s) => sources.includes(s))
    );
  }

  isSomeCountriesChecked(): boolean {
    if (!this.evaService.eva) return false;
    const countries = this.filter.location.split(',');
    return (
      !!this.evaService.eva.countries.find((c) => !countries.includes(c)) &&
      !!this.evaService.eva.countries.find((c) => countries.includes(c))
    );
  }

  isCountryChecked(country: string): boolean {
    return this.filter.location.includes(country);
  }

  isSourceChecked(source: string): boolean {
    return this.filter.source.includes(source);
  }

  toggleAll(event: MatCheckboxChange) {
    if (!this.evaService.eva) return;
    this.filter.location = event.checked
      ? this.evaService.eva.countries.join(',')
      : '';
  }

  toggleAllSources(event: MatCheckboxChange) {
    if (!this.evaService.eva) return;
    this.filter.source = event.checked
      ? this.evaService.eva.sources.join(',')
      : '';
  }
}
