import { Component } from '@angular/core';
import { AppService } from './app.service';
import { LoginService } from './login/login.service';

@Component({
  selector: 'eva-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private appService: AppService,
    private loginService: LoginService
  ) {}

  shouldUseDrawer(): boolean {
    return (
      this.loginService.isLoggedIn() &&
      this.appService.activePage != 'dashboard'
    );
  }
}
