<h2 mat-dialog-title>1. Customer data</h2>
<mat-dialog-content class="mat-typography">
  Fill in the customer data and the machine data.
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    Never show again
  </button>
  <button mat-button [mat-dialog-close]="false" class="navigate">
    <mat-icon>navigate_next</mat-icon>
  </button>
</mat-dialog-actions>
