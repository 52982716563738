<h1>Used machine evaluation - Logarithmic analysis</h1>
<h4>Calculating machine age from year {{ evaService.eva?.yearCreated }}</h4>
<div class="container" *ngIf="evaService.eva">
  <div class="column">
    <mat-card class="info" *ngIf="evaService.eva.evaCompleted">
      <mat-card-content>
        <div class="reva">
          <div>
             This EVA was completed on {{ evaService.eva.completed_at | date:'medium':'UTC +2' }} and is now frozen. If you need to make changes, start a new re-Eva:
             <button mat-flat-button (click)="startReEva()">
              Start New Re-EVA
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>Machine data</mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="name">Manufacturer</div>
          <div class="value">
            {{ evaService.eva.machine.manufacturer }}
          </div>
        </div>
        <div class="row">
          <div class="name">Model</div>
          <div class="value">{{ evaService.eva.machine.model }}</div>
        </div>
        <div class="row">
          <div class="name">Year</div>
          <div class="value">{{ evaService.eva.machine.yearOfBuild }}</div>
        </div>
        <div class="row">
          <div class="name">Operating hours</div>
          <div class="value">
            {{ evaService.eva.machine.operatingHours }}
          </div>
        </div>
        <div class="row">
          <div class="name">Expected hours for age</div>
          <div class="value">
            {{ evaService.eva.machine.expectedHoursForAge }}
          </div>
        </div>
        <div class="row">
          <div class="name">Age adjustment<br />based on hours difference</div>
          <div class="value">
            {{ evaService.eva.machine.ageAdjustment }}
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card>
      <mat-card-title>
        Condition and Configuration based adjustment
        <h6>
          These values will reflect on the EVA PDF Export
        </h6>
        <span class="loading" *ngIf="isLoading">Loading...</span>
      </mat-card-title>
      <mat-card-content>
        <div class="row">
          <div class="name">Age vs. Condition</div>
          <div class="value">
            {{ evaAgeCondition }}
          </div>
        </div>
        <div
          class="row"
          *ngFor="let adjustment of evaService.eva.priceAdjustments"
        >
          <mat-form-field appearance="outline" class="name">
            <input matInput [(ngModel)]="adjustment.name" [disabled]="evaService.eva.evaCompleted" (change)="submit()" />
          </mat-form-field>
          &euro;<mat-form-field appearance="outline" class="value">
            <input
              matInput
              type="number"
              [(ngModel)]="adjustment.value"
              [disabled]="evaService.eva.evaCompleted"
              (change)="submit()"
            />
          </mat-form-field>
          <button mat-mini-fab (click)="deleteAdjustment(adjustment)" [disabled]="evaService.eva.evaCompleted">
            <mat-icon>delete</mat-icon>
          </button>
        </div>
        <div class="row">
          <div class="name">Total adjustments</div>
          <div class="value">{{ totalAdjustments }}</div>
          <button mat-mini-fab (click)="addAdjustment()" [disabled]="evaService.eva.evaCompleted">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card>
      <mat-card-title>Notice:</mat-card-title>
      <mat-card-content>
        We made a price adjustment of -5% on Feb 16, 2023. EVAs done before that date will be affected and will now show a lower price. However, the low and high values stored in OS won't be overwritten unless you click on EVA completed again.
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="evaService.eva.evaToolResult">
      <mat-card-title>Market Price Estimation</mat-card-title>
      <mat-card-content>
        <table class="estimation" border=0 cellspacing="3">
          <tr>
            <td></td>
            <td class="var">Min recomm.</td>
            <td class="var" align="right">Max recomm.</td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td class="recomm">
              {{ minimumRecommendedPrice }}
            </td>
            <td class="recomm" align="right">
              {{ maximumRecommendedPrice }}
            </td>
            <td></td>
          </tr>
          <tr>
            <td class="lb" align="center">Selling on your own</td>
            <td class="selling" colspan=2 bgcolor=#e53e3e align="center">Selling with Equippo</td>
            <td class="rb" align="center">-5%</td>
          </tr>
          <tr>
            <td>
              {{ minimumMarketValue  }}
            </td>
            <td colspan=3 align="right">{{ averageAskingPrice }}</td>
          </tr>
          <tr>
            <td class="var">Minimum market value</td>
            <td class="var" colspan=3 align="right">Adjusted average asking price</td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
    <mat-card *ngIf="evaService.eva.evaToolResult">
      <mat-card-title>Net Prices for Seller</mat-card-title>
      <mat-card-content>
        <table class="netprices" border=0 cellspacing="0">
          <tr>
            <td colspan=2 align="center">Net Prices After our Commission</td>
          </tr>
          <tr>
            <td align="center">
              <span class="var">Minimum net</span> for seller<br/>
              <span class="net">{{ minimumNetPrice }}</span>
            </td>
            <td align="center">
              <span class="var">Maximum net</span> for seller<br/>
              <span class="net">{{ maximumNetPrice }}
              </span>
            </td>
          </tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="column">
    <mat-card>
      <mat-card-title> Current advertisement prices </mat-card-title>
      <mat-card-content>
        <img
          *ngIf="evaService.eva.evaToolResult"
          src="{{ this.evaService.plotUrl(timestamp) | secureImage | async }}"
        />
      </mat-card-content>
    </mat-card>

    <mat-card *ngIf="evaService.eva?.eq_product_id">
      <mat-card-title> Inspection Summary </mat-card-title>
      <mat-card-content>
        <iframe id="summary" [src]="url | safe" height="400" frameBorder="0"></iframe>
      </mat-card-content>
    </mat-card>
  </div>
  <!-- <div class="column images">
    <div class="add" (click)="uploadImage()"><mat-icon>add</mat-icon></div>
    <img
      class="image"
      *ngFor="let image of evaService.eva.machine.images"
      src="{{ '/api' + image.link | secureImage | async }}"
    />
  </div> -->
</div>
