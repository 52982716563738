import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-eva-completed-dialog',
  templateUrl: './eva-completed-dialog.component.html',
  styleUrls: ['./eva-completed-dialog.component.scss']
})
export class EvaCompletedDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
