<h1>Machine evaluation</h1>
<div class="container" *ngIf="evaService.eva">
  <div class="span-two-columns">
    <mat-card class="info" *ngIf="evaService.eva.evaCompleted">
      <mat-card-content>
        <div class="reva">
          <div>
            This EVA was completed on {{ evaService.eva.completed_at | date:'medium':'UTC +2' }} and is now frozen.<br>
            If you need to make changes, start a new re-Eva:<br>
            <button mat-flat-button (click)="startReEva()">
              Start New Re-EVA
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="single-column">
    <mat-card *ngIf="evaService.eva.customer">
      <mat-card-title>Customer data</mat-card-title>
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>First name</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.firstName"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last name</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.lastName"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Company name</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.company"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Street and number</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.street"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Postal code</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.postalCode"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.city"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Country</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.customer.country"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="single-column">
    <mat-card *ngIf="evaService.eva.machine">
      <mat-card-title>Machine data</mat-card-title>
      <mat-card-content>
        <mat-form-field appearance="outline">
          <mat-label>Manufacturer</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.manufacturer"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Model</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.model"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Year of build</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.yearOfBuild"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Operating hours</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.operatingHours"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
            required
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Seller manager</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.sellerManager"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Gross weight</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.grossWeight"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
          <span matSuffix>&nbsp; kg</span>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Serial number</mat-label>
          <input
            matInput
            [(ngModel)]="evaService.eva.machine.serialNumber"
            [disabled]="evaService.eva.evaCompleted"
            (change)="submit()"
          />
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Date of EVA</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            [(ngModel)]="evaService.eva.created"
            [disabled]="evaService.eva.evaCompleted"
            (dateChange)="submit()"
          />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        <span>This is the year that is used to determine the age of the machine.</span>
      </mat-card-content>
    </mat-card>
  </div>
</div>
