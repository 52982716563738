export class MachineType {
  constructor(
    public id: number,
    public manufacturerId: number,
    public manufacturer: string,
    public name: string,
    public observations: number
  ) {}

  static fromDict(m: any): MachineType {
    return new MachineType(
      m.type_id,
      m.manufacturer_id,
      m.manufacturer,
      m.name,
      m.observations
    );
  }
}
