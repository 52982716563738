import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { EvaService } from '../eva.service';

@Component({
  selector: 'app-customer-data',
  templateUrl: './customer-data.component.html',
  styleUrls: ['./customer-data.component.scss'],
})
export class CustomerDataComponent implements OnInit {
  constructor(private appService: AppService, public evaService: EvaService) {}

  ngOnInit() {
    this.appService.openDialog();
    this.evaService.getEva(this.appService.evaId);
  }

  submit() {
    this.evaService.putEva();
  }

  async startReEva() {
    await this.evaService.startReEva();
  }
}
