<mat-dialog-content>
  <h2>New Machine</h2>
  <div class="row">
    <div class="left">
      <h3>Manufacturer and Model</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.model" type="text" />
      </mat-form-field>
    </div>

    <div class="right">
      <h3>Year</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.year" type="number" />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="left">
      <h3>Hours</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.mHrs" type="number" />
      </mat-form-field>
    </div>

    <div class="right">
      <h3>Price</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.advertPrice" type="number" />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="left">
      <h3>Price Adjustment</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.priceAdjustment" type="text" />
      </mat-form-field>
    </div>

    <div class="right">
      <h3>Adjustment Comment</h3>
      <mat-form-field appearance="outline">
        <input
          matInput
          [(ngModel)]="new_machine.adjustmentComment"
          type="text"
        />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="left">
      <h3>Country</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.country" type="text" />
      </mat-form-field>
    </div>

    <div class="right">
      <h3>Date</h3>
      <mat-form-field appearance="outline">
        <input
          matInput
          [matDatepicker]="dateInfo"
          [(ngModel)]="new_machine.dateInfo"
          (click)="dateInfo.open()"
        />
        <mat-datepicker #dateInfo></mat-datepicker>
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="left">
      <h3>Condition</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.condition" type="text" />
      </mat-form-field>
    </div>
    <div class="right">
      <h3>Image Link</h3>
      <mat-form-field appearance="outline">
        <input matInput [(ngModel)]="new_machine.imgSrc" type="text" />
      </mat-form-field>
    </div>
  </div>

  <div class="row">
    <div class="left">
      <h3>Sold</h3>
      <mat-checkbox
      appearance="outline"
      [(ngModel)]="new_machine.sold"
      ></mat-checkbox>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button
    mat-button
    (click)="dismissData()"
    [mat-dialog-close]="false"
    class="close"
    cdkFocusInitial
  >
    Cancel
  </button>
  <button
    mat-button
    (click)="sendMachineData()"
    [mat-dialog-close]="true"
    class="apply"
  >
    Apply
  </button>
</mat-dialog-actions>
