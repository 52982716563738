import { FilterRange } from './filter-range';

export class Filter {
  constructor(
    public price: FilterRange,
    public year: FilterRange,
    public meter: FilterRange,
    public location: string,
    public source: string
  ) {}

  static fromDict(f: any): Filter {
    return new Filter(
      FilterRange.fromDict(f.price || {}),
      FilterRange.fromDict(f.year || {}),
      FilterRange.fromDict(f.meter || {}),
      f.location || '',
      f.source || ''
    );
  }

  toDict(): any {
    return {
      price: this.price?.toDict(),
      year: this.year?.toDict(),
      meter: this.meter?.toDict(),
      location: this.location,
      source: this.source
    };
  }
}
