<mat-dialog-content>
  <h3>Price</h3>
  <div class="inputs">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="from"
        [(ngModel)]="filter.price.min"
        type="number"
      />
    </mat-form-field>
    &mdash;
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="to"
        [(ngModel)]="filter.price.max"
        type="number"
      />
    </mat-form-field>
  </div>

  <h3>Year</h3>
  <div class="inputs">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="from"
        [(ngModel)]="filter.year.min"
        type="number"
      />
    </mat-form-field>
    &mdash;
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="to"
        [(ngModel)]="filter.year.max"
        type="number"
      />
    </mat-form-field>
  </div>

  <h3>Meter reads</h3>
  <div class="inputs">
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="from"
        [(ngModel)]="filter.meter.min"
        type="number"
      />
    </mat-form-field>
    &mdash;
    <mat-form-field appearance="outline">
      <input
        matInput
        placeholder="to"
        [(ngModel)]="filter.meter.max"
        type="number"
      />
    </mat-form-field>
  </div>

  <h3>Location</h3>
  <div class="locations" *ngIf="evaService.eva">
    <mat-checkbox
      [checked]="isAllCountriesChecked()"
      [indeterminate]="isSomeCountriesChecked()"
      (change)="toggleAll($event)"
      >all</mat-checkbox
    >
    <mat-checkbox
      *ngFor="let country of evaService.eva.countries"
      [checked]="isCountryChecked(country)"
      (change)="toggleCountry(country)"
      >{{ country }}</mat-checkbox
    >
  </div>

  <h3>Source</h3>
  <div class="sources" *ngIf="evaService.eva">
    <mat-checkbox
      [checked]="isAllSourcesChecked()"
      [indeterminate]="isSomeSourcesChecked()"
      (change)="toggleAllSources($event)"
      >all</mat-checkbox
    >
    <mat-checkbox
      *ngFor="let source of evaService.eva.sources"
      [checked]="isSourceChecked(source)"
      (change)="toggleSource(source)"
      >{{ source }}</mat-checkbox
    >
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="false" class="close" cdkFocusInitial>
    Cancel
  </button>
  <button mat-button [mat-dialog-close]="true" class="apply">Apply</button>
</mat-dialog-actions>
