import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomerDataComponent } from './customer-data/customer-data.component';
import { OverviewComponent } from './overview/overview.component';
import { ResearchComponent } from './research/research.component';
import { LoginComponent } from './login/login.component';
import { LogoutComponent } from './login/logout.component';
import { AuthComponent } from './login/auth.component';
import { AuthGuardService } from './login/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EvaFromOneSystemComponent } from './eva-from-one-system/eva-from-one-system.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'logout',
    component: LogoutComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'eva-from-one-system',
    component: EvaFromOneSystemComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: ':evaId',
    canActivate: [AuthGuardService],
    children: [
      {
        path: '',
        redirectTo: 'customerdata',
        pathMatch: 'full',
      },
      {
        path: 'customerdata',
        component: CustomerDataComponent,
      },
      {
        path: 'research',
        component: ResearchComponent,
      },
      {
        path: 'overview',
        component: OverviewComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
