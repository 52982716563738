<h1>Machine evaluation</h1>
<div class="container" *ngIf="evaService.eva">
  <div class="row">
    <mat-card class="info" *ngIf="evaService.eva.evaCompleted">
      <mat-card-content>
        <div class="reva">
          <div>
            This EVA was completed on {{ evaService.eva.completed_at | date:'medium':'UTC +2' }} and is now frozen.<br>
            If you need to make changes, start a new re-Eva:<br>
            <button mat-flat-button (click)="startReEva()">
              Start New Re-EVA
            </button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="header">
    <mat-form-field class="search" appearance="outline">
      <mat-label>New search</mat-label>
      <input
        id="search-input"
        matInput
        (input)="handleSearchInput($event)"
        (click)="handleSearchInput($event)"
        [matAutocomplete]="auto"
        [defaultValue]="defaultValue"
        [disabled]="evaService.eva.evaCompleted"
      />
      <mat-autocomplete
        #auto="matAutocomplete"
        (optionSelected)="selected($event)"
        [displayWith]="displayName"
      >
        <mat-optgroup
          *ngFor="let group of suggestions"
          [label]="group.manufacturer"
        >
          <mat-option
            *ngFor="let suggestion of group.items"
            [value]="suggestion"
          >
            <div class="suggestion">
              <strong>{{ suggestion.name }}</strong>
              <small>{{ suggestion.observations }} search results</small>
            </div>
          </mat-option>
        </mat-optgroup>
      </mat-autocomplete>
      <mat-spinner
        *ngIf="isLoadingTypes"
        matSuffixclass="search-spinner"
        [diameter]="18"
      ></mat-spinner>
      <mat-icon matSuffix (click)="handleSearchIcon()"> search </mat-icon>
    </mat-form-field>
    <div class="actions">
      <button mat-flat-button (click)="autoEva()" [disabled]="evaService.eva.evaCompleted">
        Auto Eva<mat-icon>fast_forward</mat-icon>
      </button>
      <button mat-flat-button (click)="addMachine()" [disabled]="evaService.eva.evaCompleted">
        Add machine <mat-icon>agriculture</mat-icon>
      </button>
      <button [disabled]="true" mat-flat-button (click)="openFilter()">
        Filter <mat-icon>filter_alt</mat-icon>
      </button>
    </div>
  </div>

  <div class="tableWrapper">
    <table cellspacing="0" cellpadding="0">
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>Mfr. & Model</th>
          <th>Year</th>
          <th>Hours</th>
          <th>Advert<br />Price</th>
          <th>Price<br />adjustment</th>
          <th>Adjusted<br />price</th>
          <th>Adjustment<br />comment</th>
          <th>Country</th>
          <th>Date</th>
          <th>Year</th>
          <th>Age</th>
          <th>Avg. hrs/year</th>
          <th>Condition</th>
          <th>Source</th>
          <th>Sold</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isLoadingMachines">
          <td colspan="18">
            <mat-spinner [diameter]="50"></mat-spinner>
          </td>
        </tr>
        <tr
          *ngIf="
            !isLoadingMachines && evaService.eva.comparableMachines.length == 0
          "
        >
          <td colspan="18">
            Please search for new machines. If machines were found with prices
            and images, they will be shown here. But if you have clicked on a
            machine model in the search suggestions where a non-zero count of
            search results were shown and nothing shows up here after a while,
            it is possible that none of them had any prices or images. Thank
            you.
          </td>
        </tr>
        <ng-container *ngFor="let machine of evaService.eva.comparableMachines">
          <tr *ngIf="machine.isCustom">
            <td>
              <mat-checkbox
                appearance="outline"
                [(ngModel)]="machine.isSelected"
                [disabled]="evaService.eva.evaCompleted || !machine.isEvaluable"
                (change)="submitChange(machine)"
              ></mat-checkbox>
            </td>
            <td
              *ngIf="machine.evaluationData.imgSrc != ''; else noImage"
              class="image"
            >
              <img
                src="{{ machine.evaluationData.imgSrc }}"
                alt="{{ machine.evaluationData.model }}"
              />
              <div class="hoverImage">
                <img src="{{ machine.evaluationData.imgSrc }}" alt="" />
              </div>
            </td>
            <ng-template #noImage>
              <td [matMenuTriggerFor]="imageLink" class="no-picture clickable">
                <mat-icon>add</mat-icon>
              </td>
              <mat-menu class="image-link" #imageLink="matMenu">
                <mat-form-field
                  appearance="outline"
                  (click)="$event.stopPropagation()"
                >
                  <input
                    matInput
                    placeholder="Paste image link here"
                    value="{{ machine.evaluationData.imgSrc }}"
                    [(ngModel)]="machine.evaluationData.imgSrc"
                    [disabled]="evaService.eva.evaCompleted"
                    (change)="submitChange(machine)"
                  />
                </mat-form-field>
              </mat-menu>
            </ng-template>

            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.model }}"
                  [(ngModel)]="machine.evaluationData.model"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.year }}"
                  [(ngModel)]="machine.evaluationData.year"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.mHrs }}"
                  [(ngModel)]="machine.evaluationData.mHrs"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.advertPrice }}"
                  [(ngModel)]="machine.evaluationData.advertPrice"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.priceAdjustment }}"
                  [(ngModel)]="machine.evaluationData.priceAdjustment"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>{{ machine.evaluationData.adjustedPrice }}&nbsp;€</td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.adjustmentComment }}"
                  [(ngModel)]="machine.evaluationData.adjustmentComment"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.country }}"
                  [(ngModel)]="machine.evaluationData.country"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  [matDatepicker]="dateInfo"
                  (click)="dateInfo.open()"
                  value="{{ displayDate(machine.evaluationData.dateInfo) }}"
                  [(ngModel)]="machine.evaluationData.dateInfo"
                  [disabled]="evaService.eva.evaCompleted"
                  (dateChange)="submitChange(machine)"
                />
                <mat-datepicker #dateInfo></mat-datepicker>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.date }}"
                  [(ngModel)]="machine.evaluationData.date"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>{{ machine.evaluationData.age }}</td>
            <td>{{ machine.evaluationData.mhAvrA | number: "1.0-0" }}</td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.condition }}"
                  [(ngModel)]="machine.evaluationData.condition"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>Manual</td>
            <td>{{ machine.evaluationData.sold }}</td>
            <td>
              <button mat-icon-button [matMenuTriggerFor]="more" [disabled]="evaService.eva.evaCompleted">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #more="matMenu">
                <button mat-flat-button (click)="deleteMachine(machine)" [disabled]="evaService.eva.evaCompleted">
                  <mat-icon>delete</mat-icon> &nbsp; delete
                </button>
              </mat-menu>
            </td>
          </tr>
        </ng-container>
        <ng-container *ngFor="let machine of evaService.eva.comparableMachines">
          <tr *ngIf="!machine.isCustom">
            <td>
              <mat-checkbox
                appearance="outline"
                [(ngModel)]="machine.isSelected"
                [disabled]="evaService.eva.evaCompleted || !machine.isEvaluable"
                (change)="submitChange(machine)"
              ></mat-checkbox>
            </td>
            <td class="image">
              <img
                src="{{ machine.evaluationData.imgSrc }}"
                alt="{{ machine.evaluationData.model }}"
                onerror="this.style.display='none'"
              />
              <div class="hoverImage">
                <img src="{{ machine.evaluationData.imgSrc }}" alt="" />
              </div>
            </td>
            <td>{{ machine.evaluationData.model }}</td>
            <td>{{ machine.evaluationData.year }}</td>
            <td>{{ machine.evaluationData.mHrs }}</td>
            <td>{{ machine.evaluationData.advertPrice }}&nbsp;€</td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.priceAdjustment }}"
                  [(ngModel)]="machine.evaluationData.priceAdjustment"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                  pattern="^\+?-?[0-9][0-9,\.]+\s*%?$"
                />
              </mat-form-field>
            </td>
            <td>{{ machine.evaluationData.adjustedPrice }}&nbsp;€</td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.adjustmentComment }}"
                  [(ngModel)]="machine.evaluationData.adjustmentComment"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>{{ machine.evaluationData.country }}</td>
            <td>{{ displayDate(machine.evaluationData.dateInfo) }}</td>
            <td>{{ machine.evaluationData.date }}</td>
            <td>{{ machine.evaluationData.age }}</td>
            <td>{{ machine.evaluationData.mhAvrA | number: "1.0-0" }}</td>
            <td>
              <mat-form-field appearance="outline">
                <input
                  matInput
                  value="{{ machine.evaluationData.condition }}"
                  [(ngModel)]="machine.evaluationData.condition"
                  [disabled]="evaService.eva.evaCompleted"
                  (change)="submitChange(machine)"
                />
              </mat-form-field>
            </td>
            <td>{{ machine.evaluationData.source }}</td>
            <td>{{ machine.evaluationData.sold }}</td>
            <td></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
