export class FilterRange {
  constructor(public min?: number, public max?: number) {}

  static fromDict(r: any): FilterRange {
    return new FilterRange(r.min, r.max);
  }

  toDict(): any {
    return {
      min: this.min,
      max: this.max,
    };
  }
}
