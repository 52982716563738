import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MachineType } from '../data/machine-type';
import { debounce } from '../utils/timing';
import { AppService } from '../app.service';
import { FilterDialogComponent } from '../filter-dialog/filter-dialog.component';
import { Filter } from '../data/filter';
import { EvaService } from '../eva.service';
import { ResearchMachine } from '../data/research-machine';
import { CustomMachineDialogComponent } from '../custom-machine-dialog/custom-machine-dialog.component';

@Component({
  selector: 'app-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss'],
})
export class ResearchComponent implements OnInit {
  searchTerm: string = '';
  suggestions: any[] = [];
  isLoadingMachines: boolean = false;
  isLoadingTypes: boolean = false;
  defaultValue: string = '';

  constructor(
    private appService: AppService,
    public evaService: EvaService,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    this.appService.openDialog();

    this.isLoadingMachines = true;
    if (!this.evaService.eva) {
      await this.evaService.getEva(this.appService.evaId);
    }

    this.defaultValue = this.evaService.defaultSearchTerm();
    await this.evaService.createToolResult();
    this.isLoadingMachines = false;
  }

  openFilter() {
    const filter = Filter.fromDict(this.evaService.eva?.filter?.toDict() || {});
    const dialog = this.dialog.open(FilterDialogComponent, {
      data: { filter: filter },
    });
    dialog.afterClosed().subscribe(async (apply: boolean) => {
      if (apply) {
        this.isLoadingMachines = true;
        await this.evaService.applyMachineFilter(filter);
        this.isLoadingMachines = false;
      }
    });
  }

  async autoEva() {
    await this.evaService.autoEva();
  }

  async addMachine() {
    const dialog = this.dialog.open(CustomMachineDialogComponent);
    dialog.afterClosed().subscribe(async (result) => {
      if (result != false && result != undefined) {
        await this.evaService.addResearchMachine(result);
        await this.evaService.createToolResult();
      }
    });
  }

  async selected(event: MatAutocompleteSelectedEvent) {
    this.isLoadingMachines = true;
    const manufacturer = event.option.value.manufacturer;
    const manufacturer_model = event.option.value.name;
    await this.evaService.loadMachines(event.option.value, manufacturer, manufacturer_model);
    this.isLoadingMachines = false;
  }

  displayName(suggestion: MachineType): string {
    return suggestion.name;
  }

  displayDate(date: string): string {
    return date == 'None' ? '' : new Date(date).toLocaleDateString();
  }

  handleSearchInput(event: any) {
    this.searchTerm = event.target.value;

    if (this.searchTerm == '') {
      this.suggestions = [];
      return;
    }
    debounce(() => this.submitSearch(this.searchTerm), 500, 'submitSearch');
  }

  handleSearchIcon() {
    let element: HTMLElement = document.getElementById('search-input') as HTMLElement;
    element.click();
  }

  async submitSearch(searchTerm: string) {
    this.isLoadingTypes = true;
    this.suggestions = await this.evaService.searchMachineType(searchTerm);
    this.isLoadingTypes = false;
  }

  async submitChange(researchMachine: ResearchMachine) {
    await this.evaService.putComparableMachine(researchMachine);
    await this.evaService.createToolResult();
  }

  async deleteMachine(researchMachine: ResearchMachine) {
    await this.evaService.deleteResearchMachine(researchMachine);
    await this.evaService.createToolResult();
  }

  async startReEva() {
    await this.evaService.startReEva();
  }
}
