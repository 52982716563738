<div class="container" *ngIf="evaService.eva">
  <div class="heading">
    <h3>Machine</h3>
    <div class="results">
      <span>Year</span>
      <span>{{ evaService.eva?.machine?.yearOfBuild }}</span>
      <span>Hours</span>
      <span>{{ evaService.eva?.machine?.operatingHours }}</span>
    </div>
  </div>

  <div class="heading">
    <h3>Evaluation result</h3>
  </div>

  <div class="results" *ngIf="evaService.eva.evaToolResult; else error">
    <span>Hours</span>
    <span>{{ evaService.eva.evaToolResult.mHrs }}</span>
    <span>Price</span>
    <span>{{ evaService.eva.evaToolResult.advertPrice }} €</span>
    <span>Adjusted Price</span>
    <span>{{ evaService.eva.evaToolResult.adjustedPrice }} €</span>
    <span>Age</span>
    <span>{{ evaService.eva.evaToolResult.age }}</span>
    <span>Mh avr/a</span>
    <span>{{ evaService.eva.evaToolResult.mhAvrA }}</span>
    <span>Selected machines</span>
    <span>{{ evaService.eva.selectedMachinesCount }}</span>
  </div>

  <ng-template #error>
    <div>{{ evaService.eva?.error }}</div>
  </ng-template>
</div>
