<mat-drawer-container *ngIf="shouldUseDrawer(); else outlet">
  <mat-drawer mode="side" opened>
    <app-menu></app-menu>
  </mat-drawer>

  <mat-drawer-content>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template #outlet>
  <router-outlet></router-outlet>
</ng-template>
