<input type="file" #file (change)="onFilesAdded()" multiple />
<h1 mat-dialog-title>Upload images</h1>
<mat-dialog-content fxFlex>
  <mat-list>
    <mat-list-item *ngFor="let file of files">
      <span mat-line class="filename">{{ file.name }}</span>
      <mat-progress-bar
        *ngIf="progress && progress[file.name]"
        mode="determinate"
        [value]="(progress[file.name] | async)?.percent"
        color="{{ (progress[file.name] | async)?.fail ? 'warn' : 'accent' }}"
      ></mat-progress-bar>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions>
  <div class="left">
    <button
      *ngIf="state == 'adding'"
      mat-button
      color="accent"
      (click)="addFiles()"
    >
      ADD FILES
    </button>
  </div>
  <div class="right">
    <button *ngIf="state == 'adding'" mat-button [mat-dialog-close]="false">
      CANCEL
    </button>
    <button
      *ngIf="state == 'adding' && files.size > 0"
      mat-button
      color="accent"
      (click)="upload()"
    >
      UPLOAD
    </button>
    <button
      *ngIf="state == 'finished'"
      mat-button
      color="accent"
      (click)="finish()"
    >
      FINISH
    </button>
  </div>
</mat-dialog-actions>
