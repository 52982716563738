export class EvaluationData {
  constructor(
    public model: string,
    public year: number,
    public mHrs: number,
    public advertPrice: number,
    public priceAdjustment: string,
    public adjustedPrice: number,
    public adjustmentComment: string,
    public country: string,
    public source: string,
    public sold: boolean,
    public dateInfo: string,
    public date: number,
    public age: number,
    public mhAvrA: number,
    public condition: string,
    public link: string,
    public imgSrc: string
  ) {}

  static fromDict(m: any): EvaluationData {
    return new EvaluationData(
      m.model,
      m.year,
      m.m_hrs,
      m.advert_price,
      m.price_adjustment,
      m.adjusted_price,
      m.adjustment_comment,
      m.country,
      m.source,
      m.sold,
      m.date_info,
      m.date,
      m.age,
      m.mh_avr_a,
      m.condition,
      m.link,
      m.image
    );
  }

  toDict(): any {
    return {
      source: this.source,
      sold: this.sold,
      model: this.model,
      year: this.year,
      m_hrs: this.mHrs,
      advert_price: this.advertPrice,
      price_adjustment: this.priceAdjustment,
      adjusted_price: this.computeAdjustedPrice(),
      adjustment_comment: this.adjustmentComment,
      country: this.country,
      date_info: this.dateInfo,
      date: this.dateInfoYear(),
      age: this.age,
      mh_avr_a: this.mhAvrA,
      condition: this.condition,
      link: this.link,
      image: this.imgSrc,
    };
  }

  dateInfoYear(): number {
    if(this.dateInfo) {
      const dateInfo = new Date(this.dateInfo);
      return dateInfo.getFullYear();
    } else {
      return this.date;
    }
  }

  computeAdjustedPrice(): number {
    const adjustment = this.priceAdjustment?.includes('%')
      ? (+this.priceAdjustment.replace('%', '') / 100) * this.advertPrice
      : +this.priceAdjustment;
    return +this.advertPrice + adjustment;
  }
}
