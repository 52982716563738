import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from './login.service';

@Component({
  selector: 'app-auth',
  template: '',
})
export class AuthComponent implements OnInit {
  constructor(
    public loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  async ngOnInit() {
    const state = this.route.snapshot.queryParamMap.get('state') || '';
    const code = this.route.snapshot.queryParamMap.get('code') || '';
    const returnPath = localStorage.getItem('returnPath') || '';

    if (await this.loginService.authenticate(state, code))
      if(returnPath != 'null') {
        localStorage.removeItem('returnPath');
        this.router.navigateByUrl(returnPath);
      } else {
        this.router.navigate(['/']);
      }
  }
}
