<h2 mat-dialog-title>3. Eva overview</h2>
<mat-dialog-content class="mat-typography">
  <ol>
    <li>Check that all information is in order</li>
    <li>Include price adjustments (one per line) with a description</li>
    <li>Click <strong>EVA completed</strong></li>
    <li>Download the PDF</li>
  </ol>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>
    Never show again
  </button>
  <button mat-button [mat-dialog-close]="false" class="navigate">
    <mat-icon>navigate_next</mat-icon>
  </button>
</mat-dialog-actions>
