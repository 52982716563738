import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EvaService } from '../eva.service';

@Component({
  selector: 'app-eva-from-one-system',
  templateUrl: './eva-from-one-system.component.html',
  styleUrls: ['./eva-from-one-system.component.scss']
})
export class EvaFromOneSystemComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public evaService: EvaService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.evaService.createEva(params)
                       .then((data: any) => {
                        this.router.navigate([data.id])
                       })
      }
    );
  }

}
