import { EvaluationData } from './evaluation-data';

export class ResearchMachine {
  constructor(
    public evaluationData: EvaluationData,
    public isCustom: boolean,
    public isSelected: boolean,
    public isEvaluable: boolean,
    public machineId?: string
  ) {}

  static fromDict(m: any): ResearchMachine {
    return new ResearchMachine(
      EvaluationData.fromDict(m.evaluation_data),
      m.is_custom,
      m.is_selected,
      m.is_evaluable,
      m.machine_id
    );
  }

  toDict(): any {
    return {
      evaluation_data: this.evaluationData.toDict(),
      is_custom: this.isCustom,
      is_selected: this.isSelected,
      is_evaluable: this.isEvaluable,
      machine_id: this.machineId,
    };
  }
}
