export class Customer {
  constructor(
    public salutation?: string,
    public firstName?: string,
    public lastName?: string,
    public company?: string,
    public street?: string,
    public postalCode?: string,
    public city?: string,
    public country?: string
  ) {}

  static fromDict(c: any): Customer {
    return new Customer(
      c?.salutation,
      c?.first_name,
      c?.last_name,
      c?.company,
      c?.street,
      c?.postal_code,
      c?.city,
      c?.country
    );
  }

  toDict(): any {
    return {
      salutation: this.salutation,
      first_name: this.firstName,
      last_name: this.lastName,
      company: this.company,
      street: this.street,
      postal_code: this.postalCode,
      city: this.city,
      country: this.country,
    };
  }
}
