import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { EvaService } from '../eva.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  constructor(public appService: AppService, public evaService: EvaService) {}

  hasResultOrError(): boolean {
    return (
      this.evaService.eva?.evaToolResult != undefined ||
      this.evaService.eva?.error != ''
    );
  }
}
