import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { AppService } from '../app.service';
import { Eva } from '../data/eva';
import { exclude } from '../utils/arrays';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  evas: Eva[] = [];

  get sortedEvas() {
    return this.evas.sort((a, b) => (a.created > b.created ? -1 : 1));
  }

  constructor(private http: HttpClient, public appService: AppService) {
    http.get('api/evas').subscribe((data: any) => {
      this.evas = data.evas.map((e: any) => Eva.fromDict(e));
    });
  }

  startNewEva() {
    this.http
      .post('api/evas', {})
      .subscribe((data: any) => (window.location.href = data.eva_id));
  }

  delete(eva: Eva) {
    this.http.delete('api/evas/' + eva.id).subscribe(
      () => exclude(this.evas, eva),
      (error) => console.log(error)
    );
  }
}
