<div class="header">
  <img class="logo" src="/assets/logo.svg" />
  <h1>Evaluation Editor</h1>
</div>

<h2>Dashboard</h2>

<div class="cards">
  <div *ngFor="let eva of sortedEvas">
    <a [routerLink]="['/', eva.id]">
      <span class="date">{{ eva.shortDate }}</span>
      <span class="customer">
        {{ eva.customer.firstName }} {{ eva.customer.lastName }}
        {{ eva.customer.company }}
      </span>
      <span class="machine">
        {{ eva.machine.manufacturer }} {{ eva.machine.model }}
      </span>
    </a>
    <button mat-icon-button [matMenuTriggerFor]="more">
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #more="matMenu">
      <button mat-menu-item>
        <mat-icon color="warn" (click)="delete(eva)">delete</mat-icon> delete
      </button>
    </mat-menu>
  </div>
  <button mat-flat-button (click)="startNewEva()" class="new">
    <mat-icon>add</mat-icon> New
  </button>
</div>
