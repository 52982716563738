import { AfterViewInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { Progress, UploadService } from './upload.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements AfterViewInit {
  @ViewChild('file') file: any;

  files: Set<File> = new Set();
  organizationId: string;
  projectId: string;

  state: 'adding' | 'uploading' | 'finished' = 'adding';

  progress: Progress = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    public dialogRef: MatDialogRef<UploadComponent>,
    private uploadService: UploadService
  ) {
    this.organizationId = data.organizationId;
    this.projectId = data.projectId;
  }

  ngAfterViewInit() {
    this.addFiles();
  }

  addFiles() {
    this.file.nativeElement.click();
  }

  onFilesAdded() {
    const newFiles = Array.from(this.file.nativeElement.files);
    newFiles.forEach((f: any) => this.files.add(f));
  }

  upload() {
    this.state = 'uploading';
    this.dialogRef.disableClose = true;

    this.progress = this.uploadService.upload(this.files);
    forkJoin(Object.values(this.progress)).subscribe(() => {
      this.state = 'finished';
      this.dialogRef.disableClose = false;
    });
  }

  finish() {
    if (this.state == 'finished') return this.dialogRef.close(true);
  }
}
