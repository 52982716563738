import { SecureImagePipe } from './login/secure-image';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSidenavModule } from '@angular/material/sidenav';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CustomerDataComponent } from './customer-data/customer-data.component';
import { CustomerDataDialogComponent } from './customer-data-dialog/customer-data-dialog.component';
import { EvaluationResultComponent } from './evaluation-result/evaluation-result.component';
import { EvaToolResultComponent } from './eva-tool-result/eva-tool-result.component';
import { MenuComponent } from './menu/menu.component';
import { OverviewComponent } from './overview/overview.component';
import { OverviewDialogComponent } from './overview-dialog/overview-dialog.component';
import { ResearchComponent } from './research/research.component';
import { ResearchDialogComponent } from './research-dialog/research-dialog.component';
import { FilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { UploadComponent } from './upload/upload.component';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './login/auth.component';
import { LogoutComponent } from './login/logout.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { TokenInterceptor } from './login/token-interceptor';
import { CustomMachineDialogComponent } from './custom-machine-dialog/custom-machine-dialog.component';
import { EvaFromOneSystemComponent } from './eva-from-one-system/eva-from-one-system.component';
import { SafePipe } from './safe.pipe';
import { PdfDownloadDialogComponent } from './pdf-download-dialog/pdf-download-dialog.component';
import { EvaCompletedDialogComponent } from './eva-completed-dialog/eva-completed-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    CustomerDataComponent,
    CustomerDataDialogComponent,
    EvaluationResultComponent,
    EvaToolResultComponent,
    MenuComponent,
    OverviewComponent,
    OverviewDialogComponent,
    ResearchComponent,
    FilterDialogComponent,
    ResearchDialogComponent,
    CustomMachineDialogComponent,
    UploadComponent,
    LoginComponent,
    AuthComponent,
    LogoutComponent,
    DashboardComponent,
    SecureImagePipe,
    EvaFromOneSystemComponent,
    SafePipe,
    PdfDownloadDialogComponent,
    EvaCompletedDialogComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    AppRoutingModule,
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    SecureImagePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
