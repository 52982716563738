import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Pipe({
  name: 'secureImage',
})
export class SecureImagePipe implements PipeTransform {
  // https://stackoverflow.com/a/49115219/4082686
  constructor(private http: HttpClient) {}

  transform(url: string) {
    if (url != undefined) {
      return new Observable<string>((observer) => {
        this.http.get(url, { responseType: 'blob' }).subscribe((response) => {
          const reader = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = () => observer.next(reader.result as string);
        });

        return { unsubscribe() {} };
      });
    }
    return undefined;
  }
}
