import { Component } from '@angular/core';
import { EvaService } from '../eva.service';

@Component({
  selector: 'app-evaluation-result',
  templateUrl: './evaluation-result.component.html',
  styleUrls: ['./evaluation-result.component.scss'],
})
export class EvaluationResultComponent {
  constructor(public evaService: EvaService) {}
}
