export class Image {
  constructor(public link: string, public imageId: string) {}

  static fromDict(i: any): Image {
    return new Image(i.link, i.image_id);
  }

  toDict(): any {
    return {
      link: this.link,
      image_id: this.imageId,
    };
  }
}
