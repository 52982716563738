export class EvaToolResult {
  constructor(
    public adjustedPrice: number,
    public advertPrice: number,
    public age: number,
    public mHrs: number,
    public mhAvrA: number,
    public retailerEkLocal: number,
    public retailerEkGlobalMin: number,
    public retailerEkGlobalMax: number,
    public customerEkMax: number,
    public targetPriceEmp: number,
    public targetPriceEps: number,
    public averageAskingPrice: number,
    public maximumRecommendedPrice: number,
    public minimumRecommendedPrice: number,
    public minimumMarketValue: number,
    public maximumNetPrice: number,
    public minimumNetPrice: number
  ) {}

  static fromDict(tr: any): EvaToolResult {
    return new EvaToolResult(
      tr.adjusted_price,
      tr.advert_price,
      tr.age,
      tr.m_hrs,
      tr.m_hrs_a,
      tr.retailer_ek_local,
      tr.retailer_ek_global_min,
      tr.retailer_ek_global_max,
      tr.customer_ek_max,
      tr.target_price_emp,
      tr.target_price_eps,
      tr.average_asking_price,
      tr.maximum_recommended_price,
      tr.minimum_recommended_price,
      tr.minimum_market_value,
      tr.maximum_net_price,
      tr.minimum_net_price
    );
  }

  toDict(): any {
    return {
      adjusted_price: this.adjustedPrice,
      advert_price: this.advertPrice,
      age: this.age,
      m_hrs: this.mHrs,
      m_hrs_a: this.mhAvrA,
      retailer_ek_local: this.retailerEkLocal,
      retailer_ek_global_min: this.retailerEkGlobalMin,
      retailer_ek_global_max: this.retailerEkGlobalMax,
      customer_ek_max: this.customerEkMax,
      target_price_emp: this.targetPriceEmp,
      target_price_eps: this.targetPriceEps,
      average_asking_price: this.averageAskingPrice,
      maximum_recommended_price: this.maximumRecommendedPrice,
      minimum_recommended_price: this.minimumRecommendedPrice,
      minimum_market_value: this.minimumMarketValue,
      maximum_net_price: this.maximumNetPrice,
      minimum_net_price: this.minimumNetPrice
    };
  }
}
