import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '../app.service';
import { PriceAdjustment } from '../data/price-adjustment';
import { EvaService } from '../eva.service';
import { UploadComponent } from '../upload/upload.component';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  isLoading: boolean = false;
  timestamp: number = Date.now();
  url: string = '';

  constructor(
    private dialog: MatDialog,
    private appService: AppService,
    public evaService: EvaService
  ) {}

  async ngOnInit() {
    this.appService.openDialog();
    this.isLoading = true;
    if (!this.evaService.eva)
      await this.evaService.getEva(this.appService.evaId);
    await this.evaService.createToolResult();
    this.isLoading = false;
    this.timestamp = Date.now();
    this.url = this.evaService.simplifiedInspectionSummaryUrl();;
  }

  async addAdjustment() {
    this.isLoading = true;
    if (await this.evaService.addPriceAdjustment())
      await this.evaService.createToolResult();
    this.isLoading = false;
    this.timestamp = Date.now();
  }

  async deleteAdjustment(adjustment: PriceAdjustment) {
    this.isLoading = true;
    await this.evaService.deletePriceAdjustment(adjustment);
    await this.evaService.createToolResult();
    this.isLoading = false;
    this.timestamp = Date.now();
  }

  async submit() {
    this.isLoading = true;
    if (await this.evaService.putEva())
      await this.evaService.createToolResult();
    this.isLoading = false;
    this.timestamp = Date.now();
  }

  uploadImage() {
    const dialogRef = this.dialog.open(UploadComponent, {
      width: '50%',
      height: '50%',
      data: {},
    });
    dialogRef.afterClosed().subscribe((success) => {
      if (success) this.evaService.getEva(this.appService.evaId);
    });
  }

  formatPrice(price: number) {
    const next_hundred = (((price-1)/100) | 0)*100+100;
    return `€ ${next_hundred.toLocaleString("en-NL")}`;
  }

  formatPriceNoRound(price: number) {
    const next_hundred = price | 0;
    return `€ ${next_hundred.toLocaleString("en-NL")}`;
  }

  get averageAskingPrice() {
    return this.formatPrice(this.evaService.eva!.evaToolResult!.averageAskingPrice);
  }

  get minimumMarketValue() {
    return this.formatPrice(this.evaService.eva!.evaToolResult!.minimumMarketValue);
  }

  get minimumRecommendedPrice() {
    return this.formatPrice(this.evaService.eva!.evaToolResult!.minimumRecommendedPrice);
  }

  get maximumRecommendedPrice() {
    return this.formatPrice(this.evaService.eva!.evaToolResult!.maximumRecommendedPrice);
  }

  get minimumNetPrice() {
    return this.formatPrice(this.evaService.eva!.evaToolResult!.minimumNetPrice);
  }

  get maximumNetPrice() {
    return this.formatPrice(this.evaService.eva!.evaToolResult!.maximumNetPrice);
  }

  get evaAgeCondition() {
    return this.formatPriceNoRound(this.evaService.eva!.ageCondition);
  }

  get totalAdjustments() {
    let total = 0;
    this.evaService.eva?.priceAdjustments.forEach((a) => (total += Math.trunc(a.value)));
    return this.formatPriceNoRound(total + this.evaService.eva!.ageCondition );
  }

  async startReEva() {
    this.isLoading = true;
    await this.evaService.startReEva();
    this.isLoading = false;
    this.timestamp = Date.now();
  }
}
