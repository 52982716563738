import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CustomerDataDialogComponent } from './customer-data-dialog/customer-data-dialog.component';
import { OverviewDialogComponent } from './overview-dialog/overview-dialog.component';
import { ResearchDialogComponent } from './research-dialog/research-dialog.component';
import { PdfDownloadDialogComponent } from './pdf-download-dialog/pdf-download-dialog.component';
import { EvaCompletedDialogComponent } from './eva-completed-dialog/eva-completed-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(public dialog: MatDialog) {}

  get evaId(): string {
    return location.pathname.split('/')[1];
  }

  get activePage(): string {
    const parts = location.pathname.split('/');
    return parts[parts.length - 1];
  }

  openDialog(rule: 'always' | 'checkStorage' = 'checkStorage') {
    const page = this.activePage;
    const localStorageKey = `hide_${page}_popup`;
    let dialogComponent: any;
    if (page == 'customerdata') dialogComponent = CustomerDataDialogComponent;
    else if (page == 'research') dialogComponent = ResearchDialogComponent;
    else if (page == 'overview') dialogComponent = OverviewDialogComponent;
    else throw new Error('Cannot open dialog for pathname.');

    if (rule == 'always' || localStorage.getItem(localStorageKey) != 'true') {
      const dialogRef = this.dialog.open(dialogComponent);
      dialogRef.afterClosed().subscribe((result) => {
        localStorage.setItem(localStorageKey, (result || '').toString());
      });
    }
  }

  openPdfDownloadDialog() {
    this.dialog.open(PdfDownloadDialogComponent);
  }

  openEvaCompletedDialog() {
    const dialogRef = this.dialog.open(EvaCompletedDialogComponent);

    dialogRef.afterClosed().subscribe(() => {
      location.reload();
    });
  }
}