export function include(array: Array<any>, item: any) {
  if (!array.includes(item)) array.push(item);
}

export function excludeBy(array: Array<any>, filter: (item: any) => boolean) {
  while (true) {
    const index = array.findIndex(filter);
    if (index == -1) break;
    array.splice(index, 1);
  }
}

export function exclude(array: Array<any>, item: any) {
  while (true) {
    const index = array.indexOf(item);
    if (index == -1) break;
    array.splice(index, 1);
  }
}

export function last(array: Array<any>): any | undefined {
  return array.length > 0 ? array[array.length - 1] : undefined;
}

export function groupBy(array: Array<any>, key: string) {
  return array.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
}
