import {
  HttpClient,
  HttpEventType,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { AppService } from '../app.service';

export type FileProgress = {
  percent: number;
  fail: boolean;
};

export type Progress = {
  [key: string]: Observable<FileProgress>;
};

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  constructor(private http: HttpClient, private appService: AppService) {}

  upload(files: Set<File>): Progress {
    const progress: Progress = {};

    files.forEach((file) => {
      const formData: FormData = new FormData();
      formData.append('files', file, file.name);
      const url = '/api/evas/' + this.appService.evaId + '/upload';
      const reqest = new HttpRequest('POST', url, formData, {
        reportProgress: true,
      });
      const fileProgress = new Subject<FileProgress>();
      this.http.request(reqest).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress && event.total) {
            const percent = (100 * event.loaded) / event.total;
            fileProgress.next({ percent: percent, fail: false });
          } else if (event instanceof HttpResponse) {
            fileProgress.complete();
          }
        },
        () => {
          fileProgress.next({ percent: 100, fail: true });
          fileProgress.complete();
        }
      );
      progress[file.name] = fileProgress.asObservable();
    });

    return progress;
  }
}
