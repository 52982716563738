export class PriceAdjustment {
  constructor(public name: string, public value: number, public id?: string) {}

  static fromDict(p: any): PriceAdjustment {
    return new PriceAdjustment(p.name, p.value, p.price_adjustment_id);
  }

  toDict(): any {
    return {
      name: this.name,
      value: this.value,
      price_adjustment_id: this.id,
    };
  }
}
