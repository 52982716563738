import { EvaToolResult } from './eva-tool-result';
import { Customer } from './customer';
import { Filter } from './filter';
import { Machine } from './machine';
import { ResearchMachine } from './research-machine';
import { PriceAdjustment } from './price-adjustment';

export class Eva {
  constructor(
    public id: string,
    public created: Date,
    public completed_at: Date,
    public ageCondition: number,
    public priceAdjustments: Array<PriceAdjustment>,
    public customer: Customer,
    public machine: Machine,
    public comparableMachines: ResearchMachine[],
    public filter: Filter,
    public error: string,
    public countries: Array<string>,
    public sources: Array<string>,
    public selectedMachinesCount: number,
    public eq_product_id: number,
    public evaToolResult?: EvaToolResult,
  ) {}

  getAgeInDays(date: number) {
    const now = new Date().getTime();
    const nowTime = now - new Date(now).setHours(0, 0, 0, 0);
    const dateTime = date - new Date(date).setHours(0, 0, 0, 0);
    const dt = now - date;
    return Math.floor(dt / 1000 / 60 / 60 / 24) + (nowTime < dateTime ? 1 : 0);
  }

  get shortDate(): string {
    const days = this.getAgeInDays(this.created.getTime());
    if (days == -1) return 'tomorrow';
    if (days == 0) return 'today';
    if (days == 1) return 'yesterday';
    return this.created.toDateString();
  }

  get evaCompleted(): boolean {
    return this.completed_at.getTime() > 0;
  }

  get yearCreated(): string {
    return this.created.getFullYear().toString();
  }

  static fromDict(e: any): Eva {
    return new Eva(
      e.eva_id,
      new Date(e.created),
      new Date(`${e.completed_at}Z`),
      e.age_condition,
      e.price_adjustments?.map((p: any) => PriceAdjustment.fromDict(p)) || [],
      Customer.fromDict(e.customer),
      Machine.fromDict(e.machine),
      e.comparable_machines?.map((m: any) => ResearchMachine.fromDict(m)) || [],
      Filter.fromDict(e.filters || {}),
      e.error,
      e.countries,
      e.sources,
      e.selected_machines_count,
      e.eq_product_id,
      e.tool_result ? EvaToolResult.fromDict(e.tool_result) : undefined,
    );
  }

  toDict(): any {
    return {
      eva_id: this.id,
      created: this.created.toISOString(),
      completed_at: this.completed_at,
      age_condition: this.ageCondition,
      price_adjustments: this.priceAdjustments.map((p) => p.toDict()),
      customer: this.customer.toDict(),
      machine: this.machine.toDict(),
      comparable_machines: this.comparableMachines.map((m) => m.toDict()),
      filters: this.filter.toDict(),
      error: this.error,
      countries: this.countries,
      sources: this.sources,
      selected_machines_count: this.selectedMachinesCount,
      eq_product_id: this.eq_product_id,
      tool_result: this.evaToolResult?.toDict(),
    };
  }
}
