import { Image } from './image';

export class Machine {
  constructor(
    public manufacturer?: string,
    public model?: string,
    public yearOfBuild?: Date,
    public operatingHours?: number,
    public sellerManager?: string,
    public grossWeight?: number,
    public serialNumber?: string,
    public created?: Date,
    public country?: string,
    public expectedHoursForAge?: number,
    public ageAdjustment?: number,
    public images: Array<Image> = []
  ) {}

  static fromDict(m: any): Machine {
    return new Machine(
      m?.manufacturer,
      m?.model,
      m?.year_of_build,
      m?.operating_hours,
      m?.seller_manager,
      m?.gross_weight,
      m?.serial_number,
      m?.created,
      m?.country,
      m?.expected_hours_for_age,
      m?.age_adjustment,
      m?.images?.map((i: any) => Image.fromDict(i)) || []
    );
  }

  toDict(): any {
    return {
      manufacturer: this.manufacturer,
      model: this.model,
      year_of_build: this.yearOfBuild,
      operating_hours: this.operatingHours,
      seller_manager: this.sellerManager,
      gross_weight: this.grossWeight,
      serial_number: this.serialNumber,
      created: this.created,
      country: this.country,
      expected_hours_for_age: this.expectedHoursForAge,
      age_adjustment: this.ageAdjustment,
      images: this.images.map((i) => i.toDict()),
    };
  }
}
