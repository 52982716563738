import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Eva } from './data/eva';
import { EvaluationData } from './data/evaluation-data';
import { Filter } from './data/filter';
import { MachineType } from './data/machine-type';
import { PriceAdjustment } from './data/price-adjustment';
import { ResearchMachine } from './data/research-machine';
import { exclude, groupBy } from './utils/arrays';
import { AppService } from './app.service';

@Injectable({
  providedIn: 'root',
})
export class EvaService {
  eva?: Eva;

  constructor(private http: HttpClient, private appService: AppService) {}

  getEva(id: string): Promise<boolean> {
    return this.http
      .get('api/evas/' + id)
      .toPromise()
      .then((data: any) => {
        this.eva = Eva.fromDict(data);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  putEva(): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    return this.http
      .put('api/evas/' + this.eva.id, this.eva.toDict())
      .toPromise()
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  searchMachineType(searchTerm: string): Promise<any> {
    return this.http
      .get('api/research/' + searchTerm)
      .toPromise()
      .then((data: any) => {
        if (!data.results) {
          if (this.eva)
            this.eva.error = 'Could not get search results from Intrador API';
          return;
        }
        const types = data.results.map((t: any) => MachineType.fromDict(t));
        const groups = groupBy(types, 'manufacturer');
        return Object.keys(groups).map((key) => ({
          mid: key,
          items: groups[key],
          manufacturer: groups[key][0].manufacturer,
        }));
      });
  }

  createEva(params: any = {}): Promise<any> {
    return this.http
      .post('/api/evas/create-eva-from-one-system', {}, { params: { ...params, os_base: this.oneSystemBase() } })
      .toPromise()
      .then((data: any) => {
        return Eva.fromDict(data);;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  startReEva(params: any = {}): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);

    const os_base = this.oneSystemBase();

    return this.http
      .post('/api/evas/' + this.eva.id + '/start-re-eva' , {}, { params: { ...params, os_base: os_base } })
      .toPromise()
      .then((response: any) => {
        if(response['status'] === 'ok') {
          const url: string = window.location.origin + '/' + response['uuid'];
          window.open(url, '_blank')?.focus();
        }
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  createResult(url: string, params: any = {}): Promise<boolean> {
    return this.http
      .post(url, {}, { params })
      .toPromise()
      .then((data: any) => {
        this.eva = Eva.fromDict(data);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  applyMachineFilter(filter: Filter): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    const url = `api/evas/${this.eva.id}/filter`;
    const params = {
      price: `${filter.price.min},${filter.price.max}`,
      year: `${filter.year.min},${filter.year.max}`,
      meter: `${filter.meter.min},${filter.meter.max}`,
      location: filter.location,
      source: filter.source,
    };
    return this.createResult(url, params);
  }

  loadMachines(type: MachineType, manufacturer: string, manufacturer_model: string): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    this.eva.comparableMachines = [];
    const url = `api/evas/${this.eva.id}/${type.manufacturerId}/${type.id}`;
    const params = {
      'manufacturer' : manufacturer,
      'manufacturer_model' : manufacturer_model,
    };
    return this.createResult(url, params);
  }

  createToolResult(): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    return this.createResult('api/evas/' + this.eva.id + '/results');
  }

  putComparableMachine(researchMachine: ResearchMachine): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    return this.http
      .put('api/comparable_machines/' + this.eva.id, researchMachine.toDict())
      .toPromise()
      .then((response) => {
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  addPriceAdjustment(): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    this.eva.priceAdjustments.push(new PriceAdjustment('', 0));
    return this.putEva();
  }

  deletePriceAdjustment(adjustment: PriceAdjustment): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    return this.http
      .delete('api/price_adjustments/' + adjustment.id)
      .toPromise()
      .then((response) => {
        exclude(this.eva!.priceAdjustments, adjustment);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  plotUrl(timestamp: number) {
    return '/api/evas/' + this.eva!.id + '/plot?t=' + timestamp;
  }

  openOneSystemUrl() {
      window.open(this.oneSystemBase()+'/en/products/'+this.eva!.eq_product_id, '_blank');
  }

  evaComplete(): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);

    this.appService.openEvaCompletedDialog();
    const params = {
      uuid: this.eva!.id,
      os_base: this.oneSystemBase()
    };

    const protocol = window.location.protocol;
    const base = window.location.hostname;
    const path = 'api/evas/eva-complete';
    const url = `${protocol}//${base}/${path}`;

    return this.http
      .put(url, params)
      .toPromise()
      .then((data: any) => {
        alert(data['message']);
        return true;
      })
      .catch((error: any) => {
        console.log(error);
        return false;
    });
  }

  simplifiedInspectionSummaryUrl(): string  {
    return this.oneSystemBase() + '/eva/' + this.eva!.eq_product_id + '/info?lang=en';
  }

  defaultSearchTerm(): string {
    return `${this.eva?.machine.manufacturer} ${this.eva?.machine.model}`;
  }

  oneSystemBase() {
    switch(window.location.hostname) {
      case 'localhost':
        return 'http://localhost:3000';
      case 'equippo-preview.zauberzeug.com':
        return 'https://equippostore-staging.herokuapp.com';
      case 'eva-tool-staging.equippo.com':
        return 'https://equippostore-staging.herokuapp.com';
      default:
        return 'https://onesystem.equippo.com';
    }
  }

  downloadPdf(locale: string): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);

    if(this.eva.eq_product_id) {
      this.appService.openPdfDownloadDialog();
    }

    return this.http
      .get('api/evas/' + this.eva.id + '/pdf?locale=' + locale + '&os_base=' + this.oneSystemBase(), {
        responseType: 'blob',
      })
      .toPromise()
      .then((response) => {
        let blob = new Blob([response], {
          type: 'application/pdf; charset=utf-8',
        });
        const a = document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = this.pdfFilename(locale);
        a.click();
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  pdfFilename(locale: string): string {
    const prefix = this.pdfFilePrefix(locale);
    const date = new Date().toISOString().split('T')[0];
    const manufacturer = this.eva?.machine.manufacturer;
    const model = this.eva?.machine.model;

    const fileName = [prefix, manufacturer, model, locale, date].filter((part) => !!part).join('_');
    return `${fileName}.pdf`;
  }

  pdfFilePrefix(locale: string): string | void {
    switch(locale) {
      case 'de':
        return 'Preis-Evaluation';
      case 'en':
        return 'Price Evaluation';
      case 'fr':
        return 'Estimation de Prix';
    }
  }

  getCurrentYear(): number {
    const now = new Date();
    return now.getFullYear();
  }

  getMinOrMaxYear(): number {
    if (this.eva?.filter.year.min) return this.eva?.filter.year.min;

    if (this.eva?.filter.year.max) return this.eva?.filter.year.max;

    return this.getCurrentYear();
  }

  getMinOrMaxMeterReads(): number {
    if (this.eva?.filter.meter.min) return this.eva?.filter.meter.min;

    if (this.eva?.filter.meter.max) return this.eva?.filter.meter.max;

    return 0;
  }

  getMinOrMaxPrice(): number {
    if (this.eva?.filter.price.min) return this.eva?.filter.price.min;

    if (this.eva?.filter.price.max) return this.eva?.filter.price.max;

    return 0;
  }

  autoEva(): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    return this.http
      .put('api/evas/auto-eva/' + this.eva.id, {})
      .toPromise()
      .then((response) => {
        console.log(response);
        window.location.reload();
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }

  addResearchMachine(machine: EvaluationData): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    const comparableMachine = new ResearchMachine(machine, true, false, true);
    return this.putComparableMachine(comparableMachine);
  }

  deleteResearchMachine(researchMachine: ResearchMachine): Promise<boolean> {
    if (!this.eva) return new Promise(() => false);
    return this.http
      .delete('api/comparable_machines/' + researchMachine.machineId)
      .toPromise()
      .then((response) => {
        exclude(this.eva!.comparableMachines, researchMachine);
        return true;
      })
      .catch((error) => {
        console.log(error);
        return false;
      });
  }
}
