import { Component } from '@angular/core';
import { EvaService } from '../eva.service';

@Component({
  selector: 'app-eva-tool-result',
  templateUrl: './eva-tool-result.component.html',
  styleUrls: ['./eva-tool-result.component.scss'],
})
export class EvaToolResultComponent {
  constructor(public evaService: EvaService) {}
}
