<div class="container">
  <a [routerLink]="['']"><img class="logo" src="/assets/logo.svg" /></a>

  <div class="menu">
    <a
      class="menuitem"
      [routerLink]="['/', appService.evaId, 'customerdata']"
      routerLinkActive="active"
    >
      <span>1.</span>
      <span>Customer data</span>
    </a>
    <a
      class="menuitem"
      [routerLink]="['/', appService.evaId, 'research']"
      routerLinkActive="active"
    >
      <span>2.</span>
      <span>Online market research</span>
    </a>
    <a
      class="menuitem"
      [routerLink]="['/', appService.evaId, 'overview']"
      routerLinkActive="active"
    >
      <span>3.</span>
      <span>EVA overview</span>
    </a>
  </div>

  <div *ngIf="appService.activePage == 'research' && hasResultOrError()">
    <app-evaluation-result></app-evaluation-result>
  </div>

  <div *ngIf="appService.activePage == 'overview' && hasResultOrError()">
    <app-eva-tool-result></app-eva-tool-result>
    <div *ngIf="evaService.eva?.evaToolResult">
      <div class="heading">
        <h3>Download</h3>
      </div>

      <button class="pdf" (click)="evaService.downloadPdf('en')" mat-flat-button>
        EN
      </button>
      <button class="pdf" (click)="evaService.downloadPdf('de')" mat-flat-button>
        DE
      </button>
      <button class="pdf" (click)="evaService.downloadPdf('fr')" mat-flat-button>
        FR
      </button>

      <div>
        <div class="heading">
          <h3>OneSystem Actions</h3>
          <button *ngIf="evaService.eva?.eq_product_id; else error"
            class="pdf"
            (click)="evaService.evaComplete()"
            [disabled]="evaService.eva?.evaCompleted"
            mat-flat-button>
            EVA Completed
          </button>
          <button *ngIf="evaService.eva?.eq_product_id" class="pdf" (click)="evaService.openOneSystemUrl()" mat-flat-button>
            Show OneSystem Machine
          </button>
          <ng-template #error>
            <h3>This is manually created evaluation, so machine in OneSystem is unknown.</h3>
          </ng-template>
        </div>
      </div>
    </div>
  </div>

  <div class="fill"></div>

  <div class="navigation">
    <button mat-button class="info" (click)="appService.openDialog('always')">
      <mat-icon>info</mat-icon>
    </button>
    <button
      *ngIf="appService.activePage != 'customerdata'; else placeholder"
      mat-button
      [routerLink]="[
        '/',
        appService.evaId,
        appService.activePage == 'research' ? 'customerdata' : 'research'
      ]"
      routerLinkActive="active"
    >
      <mat-icon>navigate_before</mat-icon>
    </button>
    <ng-template #placeholder>
      <button mat-button class="hidden">
        <mat-icon>navigate_before</mat-icon>
      </button>
    </ng-template>
    <button
      *ngIf="appService.activePage != 'overview'"
      mat-button
      [routerLink]="[
        '/',
        appService.evaId,
        appService.activePage == 'customerdata' ? 'research' : 'overview'
      ]"
      routerLinkActive="active"
    >
      <mat-icon>navigate_next</mat-icon>
    </button>
  </div>
</div>
