<div class="container" *ngIf="evaService.eva">
  <div class="heading">
    <h3>Eva tool result</h3>
    <span class="subline">equippo.com</span><br>
    <span class="subline">Date of Eva: {{ evaService.eva.created | date:'mediumDate' }}</span><br>
    <span class="subline" *ngIf="evaService.eva.evaCompleted">Eva Completed: {{ evaService.eva.completed_at | date:'mediumDate':'UTC +2' }}</span>
  </div>

  <ng-container *ngIf="evaService.eva.evaToolResult; else error">
    <div class="item">
      <span>Adjusted Average Asking Price</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.averageAskingPrice }} €
      </div>
    </div>
    <div class="item">
      <span>Maximum Recommended Price</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.maximumRecommendedPrice }} €
      </div>
    </div>
    <div class="item">
      <span>Minimum Recommended Price</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.minimumRecommendedPrice }} €
      </div>
    </div>
    <div class="item">
      <span>Minimum Market Value</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.minimumMarketValue }} €
      </div>
    </div>
    <div class="item">
      <span>Maximum Net</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.maximumNetPrice }} €
      </div>
    </div>
    <div class="item">
      <span>Minimum Net</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.minimumNetPrice }} €
      </div>
    </div>
<!--
    <div class="item">
      <span>Regional Trader</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.retailerEkLocal }} €
      </div>
      <span>15,0%</span>
    </div>
    <div class="item">
      <span>Global Trader (min)</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.retailerEkGlobalMin }} €
      </div>
      <span>0,0%</span>
    </div>
    <div class="item">
      <span>Global Trader (max)</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.retailerEkGlobalMax }} €
      </div>
      <span>10,0%</span>
    </div>
    <div class="item">
      <span>End Customer (max)</span>
      <div class="value">
        {{ evaService.eva.evaToolResult.customerEkMax }} €
      </div>
      <span>22,5%</span>
    </div>
    <span class="small-label">Assumed Margin</span>
-->
  </ng-container>

  <ng-template #error>
    <div>{{ evaService.eva?.error }}</div>
  </ng-template>
</div>
