import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  isLoggedIn(): boolean {
    return Date.now() / 1000 < parseInt(localStorage.getItem('expires') || '0');
  }

  login() {
    this.http
      .get('api/login')
      .subscribe((data: any) => (window.location.href = data));
  }

  logout() {
    localStorage.removeItem('token');
    localStorage.removeItem('expires');
  }

  async authenticate(state: string, code: string): Promise<boolean> {
    if (!(await this.validate_state(state))) return false;
    if (!(await this.ask_for_token(code))) return false;
    return true;
  }

  validate_state(state: string): Promise<boolean> {
    return this.http
      .get('api/validate', { params: { state: state }, observe: 'response' })
      .toPromise()
      .then((data: any) => data.status == 200)
      .catch(() => false);
  }

  ask_for_token(code: string): Promise<boolean> {
    return this.http
      .get(`api/token`, { params: { code: code }, observe: 'response' })
      .toPromise()
      .then((data: any) => {
        if (data.status == 200) {
          localStorage.setItem('token', data.body.Authorization);
          localStorage.setItem('expires', data.body.Expires);
        }
        return data.status == 200;
      });
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
